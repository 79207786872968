@font-face {
  font-family: "Nickname";
  src: url(./assets/fonts/nickname.ttf) format("truetype");
}

html {
  font-size: min(2.5vh, 5vw);
}

body {
  margin: 0;
  font-family: "Nickname";
}
